import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ContactModal from "../../components/ContactModal/ContactModal";
import HeaderPage from "../../components/HeaderPage/HeaderPage";

const PrivacyPolicy = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);


  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <HeaderPage />
      <section className="max-w-[1200px] mx-auto max-xl:px-5 py-20 max-md:px-8 max-md:py-10 flex flex-col items-start md:pl-8 text-black leading-normal text-font-plus-jakarta">

        <h1 className="flex self-center justify-center items-center text-4xl font-bold">
          Privacy Policy
        </h1>
        <div className="mt-10">
          <span className="font-bold mt-5">Lopez Property Care, LLC (LPC)</span>{" "}
          is committed to protecting your privacy and ensuring that your
          personal information is handled in a safe and responsible manner. This Privacy Policy explains
          what information we collect, how we use it, and your rights regarding that information.
        </div>
        <div className="font-bold mt-10 mb-3 text-[20px]">1. Information We Collect</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            We may collect various types of personal information including, but not limited to, your
            name, email address, phone number, and other contact details when you interact with
            our website or opt in to receive communications from us. In particular, if you provide
            your mobile phone number to receive SMS communications, this information is
            collected solely for that purpose.
          </li>

        </ul>
        <div className="font-bold mt-10 mb-3 text-[20px]">2. How We Use Your Information</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            We use your personal information to:
          </li>
          <li className="mt-3 list-disc">
            Provide and improve our services.
          </li>
          <li className="mt-3 list-disc">
            Communicate with you about your orders, services, and updates.
          </li>
          <li className="mt-3 list-disc">
            Process your inquiries and support requests.
          </li>
          <li className="mt-3 list-disc">
            Send you marketing communications only if you have opted in to receive them.
          </li>
        </ul>
        <div className="font-bold mt-10 mb-3 text-[20px]">3. Mobile/SMS Communications</div>
        <div className="font-bold mt-4">Opt-In and Consent:</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            By opting in to receive SMS communications from Lopez Property Care, you expressly
            consent to receive text messages regarding our services, updates, and other related
            information. The consent process is separate from other forms of communication, and
            you acknowledge that your mobile number will be used exclusively for SMS purposes.
          </li>
        </ul>
        <div className="font-bold mt-3">Data Use for SMS:</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            Your mobile phone number, when provided as an opt-in for SMS communications, will
            be used solely to send you messages regarding our services and updates. We will not
            share, sell, or otherwise distribute your mobile phone number or SMS opt-in information
            with any third parties.
          </li>
        </ul>
        <div className="font-bold mt-3">Opt-Out:</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            You may withdraw your consent at any time by replying “STOP” to any SMS message you
            receive from us. Upon receipt of your opt-out request, we will cease sending SMS
            communications to your mobile number.
          </li>
        </ul>
        <div className="font-bold mt-10 mb-3 text-[20px]">4. Data Sharing and Third-Party Disclosure</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            We do not share your personal information—especially your mobile phone number and
            SMS opt-in data—with third parties for their own marketing or other purposes without
            your explicit consent. Any data shared with third-party service providers is strictly for the
            purpose of supporting our services and is subject to confidentiality obligations.
          </li>
        </ul>
        <div className="font-bold mt-10 mb-3 text-[20px]">5. Data Retention and Security</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            We store your personal data securely and retain it only as long as necessary to fulfill the
            purposes described in this policy, including any legal or reporting requirements. We use
            industry-standard security measures to protect your information from unauthorized
            access, disclosure, alteration, or destruction.
          </li>
        </ul>
        <div className="font-bold mt-10 mb-3 text-[20px]">6. Your Rights</div>
        <div className="font-bold mt-3 mb-3">You have the right to:</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            Access and update your personal information.
          </li>
          <li className="mt-3 list-disc">
            Withdraw your consent for SMS communications (as noted above).
          </li>
          <li className="mt-3 list-disc">
            Request that we delete your personal data, subject to legal and contractual restrictions.
          </li>
          <li className="mt-3 list-disc">
            To exercise these rights, please contact us using the information provided below.
          </li>
        </ul>
        <div className="font-bold mt-10 mb-3 text-[20px]">7. Changes to This Privacy Policy</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            We may update this Privacy Policy from time to time. Any changes will be posted on this
            page with an updated effective date. We encourage you to review the policy periodically
            to stay informed about how we are protecting your information.
          </li>
        </ul>
        <div className="font-bold mt-10 mb-3 text-[20px]">8. Contact Us</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            If you have any questions or concerns about this Privacy Policy or our data practices,
            please contact us at:<a
              href="mailto:April@lopezpropertycare.com"
              className="ml-2 cursor-pointer leading-[30.2px] text-[16px] max-md:text-[10px] underline text-blue-600"
            >
              April@lopezpropertycare.com
            </a> or submit a request through <span
              className="cursor-pointer underline text-blue-600"
              onClick={() => setIsOpen(true)}
            >
              our form.
            </span>
          </li>
        </ul>
        <div className="font-bold text-xl mt-10">
          Last Updated: <span className="font-normal ">03/03/2025</span>
        </div>
      </section>
      <ContactModal open={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default PrivacyPolicy;
