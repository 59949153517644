import { Element } from "react-scroll";
import Section1 from "../../components/section1/section1";
import Section2 from "../../components/section2/section2";
import Section3 from "../../components/section3/section3";
import Section4 from "../../components/section4/section4";
import Section5 from "../../components/section5/section5";
import Section6 from "../../components/section6/section6";
import Section7 from "../../components/section7/section7";
import CustomHeader from "../../components/CustomHeader/CustomHeader";
const HomePage = () => {
  return (
    <div>
      <CustomHeader /> 
      <Element name="element" className="element">
        <Section1 />
      </Element>
      <Element name="element1" className="element">
        <Section2 />
      </Element>
      <Element name="element2" className="element">
        <Section3 />
      </Element>
      <Element name="element3" className="element">
        <Section4 />
      </Element>
      <Element name="element4" className="element">
        <Section5 />
      </Element>
      <Element name="element5" className="element">
        <Section6 />
      </Element>
      <Element name="element6" className="element">
        <Section7 />
      </Element>
    </div>
  );
};
export default HomePage;
