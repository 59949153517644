const CustomHeader = () => {
  return (
    <header className="header">
      <div
        className="text"
        alt="At Lopez Property Care, we take pride in offering top-notch property preservation services across the United States."
        title="At Lopez Property Care, we take pride in offering top-notch property preservation services across the United States."></div>
      <div className="rectangle-1-copy"></div>
    </header>
  );
};
export default CustomHeader;
