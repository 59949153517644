import { useContext } from "react";
import { scroller } from "react-scroll";
import { Context } from "../../context/Context";
import EmailCopy2 from "../../images/email_41_copy_2.png";
import fb from "../../images/facebook_29.png";
import Instagram30 from "../../images/instagram_30.png";
import Iphone22Copy2 from "../../images/iphone_22_copy_2.png";
import Layer1Copy from "../../images/logo.png";

import Linked from "../../images/linkedin_23.png";
import Pin67 from "../../images/pin_67.png";
const CustomFooter = () => {
  const [, setContext] = useContext(Context);
  const scrollData = (key, id) => {
    setContext(id);
    scroller.scrollTo(key, {
      duration: 2000,
      delay: 0,
      smooth: true,
    });
  };
  return (
    <footer className="col-6 ">
      <div className="l-constrained-2 md:max-w-[1600px] pt-5">
        <div className="flex group justify-between max-md:flex-wrap">
          <div className="flex flex-col max-md:w-[30%]">
            <img
              loading="lazy"
              className="layer-1-copy w-[100%] md:w-[93px] md:h-[130px]"
              src={Layer1Copy}
              alt="Layer1Copy"
            />
            <div className="row-26 group hidden">
              <img loading="lazy" src={fb} alt="" width="25" height="25" />
              <img
                loading="lazy"
                src={Instagram30}
                alt="Instagram30"
                width="25"
                height="25"
              />
              <img
                loading="lazy"
                src={Linked}
                alt="Linked"
                width="25"
                height="25"
              />
            </div>
          </div>
          <div className="group max-md:w-[50%] ">
            <p className="text-50 max-md:text-left">Quick Links</p>
            <div className="flex flex-col justify-start items-start text-left max-md:mt-5 mt-10 text-medium">
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element", 0)}
              >
                Home
              </button>
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element3", 0)}
              >
                Services
              </button>
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element6", 0)}
              >
                FAQ
              </button>
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element4", 0)}
              >
                Careers
              </button>
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element5", 0)}
              >
                Coverage
              </button>
            </div>
          </div>
          <div className="text-left max-md:w-[100%] max-md:mt-10 max-md:pl-5">
            <p className="services-2">Services</p>
            <div className="flex flex-col justify-start items-start max-md:mt-5 mt-10">
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element3", 0)}
              >
                Lawn/Yard Maintenance
              </button>
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element3", 1)}
              >
                Property Inspection
              </button>
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element3", 2)}
              >
                Debris Removal
              </button>
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element3", 3)}
              >
                Repairs and Renovations
              </button>
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element3", 4)}
              >
                Winterization
              </button>
              <button
                className="cursor-pointer text-grey leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
                onClick={() => scrollData("element3", 5)}
              >
                Securing
              </button>
            </div>
          </div>
          <div className="text-left  max-md:w-[100%] max-md:mt-10 max-md:pl-3">
          <p className="text-53">Policy</p>
          <div className="mt-8 flex flex-col">
              <a
                href="/privacy-policy"
                className="cursor-pointer text-grey break-words leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
              >
                Privacy Policy
              </a>
              <a
                href="/term-and-conditions"
                className="cursor-pointer  text-grey break-words leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
              >
                Terms and Condition
              </a></div>
          </div>
          <div className="text-left  max-md:w-[100%] max-md:mt-10 max-md:pl-3">
          
            <p className="text-53">Contact Us</p>
            <div className="row-31 group hidden justify-center items-center w-full">
              <img
                loading="lazy"
                className="pin-67"
                src={Pin67}
                alt="Pin67"
                width="25"
                height="30"
              />
              <span className="cursor-pointer flex-1 -ml-2 text-grey leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta">
                Aventura, FL 33180
              </span>
            </div>
            <div className="row-32 group flex items-center w-full">
              <img
                loading="lazy"
                className="iphone-22-copy-2"
                src={Iphone22Copy2}
                alt="Iphone22Copy2"
                width="20"
                height="20"
              />
              <a
                href="tel:559-214-5595"
                className="cursor-pointer flex-1 ml-1 text-grey leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
              >
                (559) 214-5595 | Ext. 103
              </a>
            </div>
            <div className="my-5 group flex justify-center items-center w-full">
              <img
                loading="lazy"
                className="email-41-copy-2 pt-2 ml-[6px]"
                src={EmailCopy2}
                alt="EmailCopy2"
                width="17"
                height="13"
              />
              <a
                href="mailto:April@lopezpropertycare.com"
                className="cursor-pointer flex-1 ml-3 text-grey break-words leading-[30.2px] text-[16px] text-medium text-font-plus-jakarta"
              >
                April@lopezpropertycare.com
              </a>
            </div>
          </div>
        </div>
        <div className="shape-10"></div>
        <div className="flex justify-center items-center mt-2">

        </div>
        <div className="text-grey text-center leading-[30.2px] text-[16px] text-font-plus-jakarta mt-2">
          © Copyright All Right Reserved {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
};
export default CustomFooter;
