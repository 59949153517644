import { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import sendBrevoEmail from "../../apis/email";
import Email from "../../images/Email.png";
import Location from "../../images/Location.png";
import Phone from "../../images/Phone.png";
import { emailTemplateContactUs } from "../../utils/template";

const ContactModal = ({open,setIsOpen}) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const onSubmit = async () => {
    if (name !== "" && email !== "" && subject !== "" && message !== "") {
      if (!isValidEmail(email)) {
        toast("Email is invalid! ");
      } else {
        await sendBrevoEmail(
          "POST",
          {},
          {
            to: [
              {
                email:
                  process.env.REACT_APP_BREVO_RECEIVER ||
                  "April@lopezpropertycare.com",
                name: "Lopez Property Preservation Admin",
              },
            ],
            subject: "New Form Submission Received",
            body: emailTemplateContactUs(name, email, subject, message),
          }
        );

        toast("Successfully submit!");
        setSubject("");
        setEmail("");
        setName("");
        setMessage("");
        setIsOpen(false);
      }
    } else if (
      name === "" &&
      email === "" &&
      subject === "" &&
      message === ""
    ) {
      toast("All field is Required!");
    } else if (name === "") {
      toast("Name field is Required!");
    } else if (email === "") {
      toast("Email field is Required!");
    } else if (subject === "") {
      toast("Subject field is Required!");
    } else if (message === "") {
      toast("Message field is Required!");
    }
  };
  return (
      <Modal
        isOpen={open}
        onRequestClose={()=>setIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        <div className="md:w-[750px] max-md:w-[300px] ">
          <div className="flex justify-evenly items-center flex-wrap">
            <div className="hidden flex-col justify-center items-center">
              <img
                loading="lazy"
                src={Location}
                alt="location"
                className="w-[50px]"
              />
              <label className="my-2 font-extrabold text-black">Location</label>
              <label className="font-normal">Aventura, FL 33180</label>
            </div>
            <div className="flex flex-col justify-center items-center md:-ml-10">
              <img
                loading="lazy"
                src={Email}
                alt="location"
                className="w-[50px]"
              />
              <label className="my-2 font-extrabold text-black">Email Us</label>
              <label className="font-normal">
              April@lopezpropertycare.com
              </label>
            </div>
            <div className="flex flex-col justify-center items-center max-md:mt-5">
              <img
                loading="lazy"
                src={Phone}
                alt="location"
                className="w-[50px]"
              />
              <label className="my-2 font-extrabold text-black">Call Us</label>
              <label className="font-normal">(559) 214-5595 | Ext. 103</label>
            </div>
          </div>
          <div className="overflow-auto max-h-[500px] my-5">
            <div className="flex flex-wrap">
              <div className="flex flex-col flex-1 md:mr-5">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  className="border rounded-md my-2 p-4 border-red"
                />
              </div>
              <div className="flex flex-col flex-1">
                <input
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="border rounded-md my-2 p-4 border-red"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <input
                value={subject}
                type="text"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                className="border rounded-md my-2 p-4 border-red"
              />
            </div>
            <div className="flex flex-col">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message "
                className="border rounded-md my-2 p-4 border-red"
              />
            </div>
          </div>
          <div className="flex gap-5 justify-center ">
            <button
              className="rounded-rectangle-1-copy-4-holder-form font-plus h-[50px]"
              onClick={() => onSubmit()}
            >
              Send Message
            </button>
          </div>
        </div>
      </Modal>
  );
};
export default ContactModal;
