import { useContext } from "react";
import { scroller } from "react-scroll";
import { Context } from "../../context/Context";
import Email41 from "../../images//email_41.png";
import Fb from "../../images/facebook_29.png";
import instagram_30 from "../../images/instagram_30.png";
import Phone12 from "../../images/iphone_22.png";
import Linkedin_23 from "../../images/linkedin_23.png";
import Logo from "../../images/logo.png";
import Shape_6 from "../../images/shape_6.png";
import { useNavigate, useLocation } from "react-router-dom";

const HeaderPage = () => {
  const [, setContext] = useContext(Context);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const scrollData = (key, id) => {
    if (pathname === "/") {
      setContext(id);
      scroller.scrollTo(key, {
        duration: 2000,
        delay: 0,
        smooth: true,
      });
    } else {
      navigate('/')
      setTimeout(() => {
        setContext(id);
        scroller.scrollTo(key, {
          duration: 2000,
          delay: 0,
          smooth: true,
        });
      }, 200);
    }
  };
  return (
    <header className="relative">
      <div className="md:mx-auto md:max-w-[1600px] flex-wrap">
        <div className="wrapper-15 relative">
          <div className="row-3 group justify-center items-center lg:pl-[6.7%]">
            <div className="flex justify-center items-center">
              <img
                loading="lazy"
                className="h-[25px] cursor-pointer "
                src={Phone12}
                alt=""
                width="25"
                height="25"
              />
              <a
                href="tel:559-214-5595"
                className="cursor-pointer text-white leading-[30.2px] text-[16px] max-md:text-[8px] text-medium text-font-plus-jakarta whitespace-nowrap"
              >
                (559) 214-5595 | Ext. 103
              </a>
            </div>
            <div className="flex justify-center items-center ml-8">
              <img
                loading="lazy"
                className="max-md:h-[15px] h-[20px] cursor-pointer"
                src={Email41}
                alt="email"
                width="17"
                height="13"
              />
              <a
                href="mailto:April@lopezpropertycare.com"
                className="ml-2 cursor-pointer text-white leading-[30.2px] text-[16px] max-md:text-[10px] text-medium text-font-plus-jakarta"
              >
                April@lopezpropertycare.com
              </a>
            </div>

            <div className="hidden">
              <div className="shape-6 max-md:hidden"></div>
              <img
                loading="lazy"
                className="facebook-29 "
                src={Fb}
                alt="facebook"
                width="25"
                height="25"
              />
              <img
                loading="lazy"
                className="instagram-30"
                src={instagram_30}
                alt="instagram"
                width="25"
                height="25"
              />
              <img
                loading="lazy"
                className="linkedin-23"
                src={Linkedin_23}
                alt="linkedin"
                width="25"
                height="25"
              />
            </div>
          </div>
          <div className="rectangle-5 max-lg:hidden"></div>
          <img
            loading="shape"
            className="shape-6-2 max-lg:hidden"
            src={Shape_6}
            alt=""
            width="17"
            height="7"
          />
        </div>
        <div className="flex justify-between items-center md:px-20 lg:px-32 md:-mt-20 lg:-mt-20 max-md:mt-5 max-md:px-3 max-md:pt-0">
          <a href="/" className="cursor-pointer">
            <img
              loading="lazy"
              className="w-[80px] h-[100px] lg:w-[112px] lg:h-[140px] max-md:w-[40px] max-md:h-[40px] max-lg:w-[60px] max-lg:h-[70px] cursor-pointer"
              src={Logo}
              alt="logo"
              width="100%"
              height="100%"
            />
          </a>
          <nav className="flex justify-center items-center">
            <ul className="flex nav-list group font-bold">
              <li>
                <p
                  className="cursor-pointer uppercase"
                  onClick={() => scrollData("element", 0)}
                >
                  Home
                </p>
              </li>
              <li>
                <p
                  className="cursor-pointer uppercase"
                  onClick={() => scrollData("element3", 0)}
                >
                  Services
                </p>
              </li>
              <li>
                <p
                  className="cursor-pointer uppercase"
                  onClick={() => scrollData("element6", 0)}
                >
                  FAQ
                </p>
              </li>

              <li>
                <p
                  className="cursor-pointer uppercase"
                  onClick={() => scrollData("element4", 0)}
                >
                  Careers
                </p>
              </li>
              <li>
                <p
                  className="cursor-pointer uppercase"
                  onClick={() => scrollData("element5", 0)}
                >
                  Coverage
                </p>
              </li>
            </ul>
          </nav>
          <button
            className="rounded-rectangle-1-holder cursor-pointer z-10 hidden"
            onClick={() => scrollData("element4")}
          >
            GET IN TOUCH
          </button>
        </div>
      </div>
    </header>
  );
};
export default HeaderPage;
