import { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomFooter from "./components/CustomFooter/CustomFooter.js";
import { Context } from "./context/Context.js";
import HomePage from "./pages/HomePage/HomePage.js";
import TermsAndCondition from "./pages/TermsAndCondition/TermsAndCondition.js";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.js";
import "./css/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function App() {
  const [context, setContext] = useState(0);
  return (
    <Context.Provider value={[context, setContext]}>
      <Router>
        <div className="App">
          <div className="">
            <Routes>
              <Route exact path="/" element={<HomePage />}></Route>
              <Route element={<PrivacyPolicy />} path="/privacy-policy" />
              <Route
                element={<TermsAndCondition />}
                path="/term-and-conditions"
              />
            </Routes>
            <CustomFooter />
            <ToastContainer />
          </div>
        </div>
      </Router>
    </Context.Provider>
  );
}

export default App;
