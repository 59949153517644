import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import ContactModal from "../../components/ContactModal/ContactModal";

const TermsAndCondition = () => {
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <HeaderPage />
      <section className="max-w-[1200px] mx-auto max-xl:px-5 py-20 max-md:px-8 max-md:py-10 flex flex-col items-start md:pl-8 text-black leading-normal text-font-plus-jakarta">
        <h1 className="flex self-center justify-center items-center text-4xl font-bold">
          Terms and Conditions of Use
        </h1>
        <div className="font-bold mt-10 mb-3 text-[20px]">1. Acceptance of Terms</div>
        <div>
          This website (“Site”) is operated by <span className="font-bold">Lopez Property Care, LLC (“LPC”).</span>
          By accessing or using this Site, you agree to comply with these Terms
          and Conditions (“Terms”) and all applicable laws. If you do not agree,
          discontinue use immediately. LPC reserves the right to modify these
          Terms at any time by updating this page. Continued use constitutes
          acceptance of revisions.
        </div>
        <div className="font-bold mt-10 mb-3 text-[20px]">2. Governing Law</div>
        <div>
          These Terms are governed by and construed in accordance with the laws of the <span className="font-bold">State of
            California, United States,</span> without regard to conflict of law principles. Any disputes arising from
          your use of this Site shall be resolved exclusively in the courts of Fresno County, California.
        </div>
        <div className="font-bold mt-10 mb-3 text-[20px]">3. Disclaimers</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            <span className="font-normal">
              <span className="font-bold">“AS IS” Basis:</span> The Site and all content (including materials,
              information, and services) are provided <span className="font-bold">“AS IS” </span>and <span className="font-bold">“AS AVAILABLE”</span>
              without warranties of any kind, express or implied. LPC expressly
              disclaims warranties of merchantability, fitness for a particular
              purpose, non-infringement, or accuracy.
            </span>
          </li>
          <li className="mt-3 list-disc">
            <span className="font-normal">
              <span className="font-bold">Informational Use:</span> Content is for general informational purposes
              only and does not constitute professional advice. While LPC strives
              to provide accurate and current information, we do not guarantee its
              completeness, reliability, or suitability for any purpose.
            </span>
          </li>
        </ul>
        <div className="font-bold mt-10 mb-3 text-[20px]">4. Limitation of Liability</div>
        <div>
          To the fullest extent permitted by law, <span className="font-bold">LPC,</span> its affiliates, officers,
          directors, employees, agents, or suppliers <span className="font-bold">shall not be liable</span> for any
          direct, indirect, incidental, consequential, punitive, or special
          damages (including lost profits, data loss, business interruption, or
          reputational harm) arising from:
        </div>
        <ul className="md:ml-10">
          <li className="font-bold mt-3 list-disc">
            <span className="font-normal">
              Your use of or inability to use the Site.
            </span>
          </li>
          <li className="font-bold mt-3 list-disc">
            <span className="font-normal">
              Reliance on Site content or third-party links.
            </span>
          </li>
          <li className="font-bold mt-3 list-disc">
            <span className="font-normal">
              Unauthorized access, malware, or data breaches.
            </span>
          </li>
        </ul>
        <div className="mt-4">
          This limitation applies regardless of legal theory (contract, tort,
          negligence, etc.), even if LPC has been advised of potential damages.
        </div>
        <div className="font-bold mt-10 mb-3 text-[20px]">5. Third-Party Links</div>
        <div>
          The Site may contain links to third-party websites for convenience. LPC:
        </div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            <span className="font-normal">
              Does not endorse or control the content, products, or services on
              these sites.
            </span>
          </li>
          <li className="mt-3 list-disc">
            <span className="font-normal ">
              Is not responsible for the accuracy, legality, or safety of
              third-party content.
            </span>
          </li>
          <li className="mt-3 list-disc">
            <span className="font-normal ">
              Disclaims all liability for damages arising from your use of
              third-party links.
            </span>
          </li>
        </ul>
        <div className="mt-4">
          You access linked sites at your own risk and should review their terms
          and privacy policies.
        </div>
        <div className="font-bold mt-10 mb-3 text-[20px]">6. Intellectual Property</div>
        <div>
          All Site content (text, graphics, logos, software) is the property of
          LPC or its licensors and is protected by U.S. and international
          copyright laws. Unauthorized use (reproduction, distribution,
          modification) is strictly prohibited.
        </div>
        <div className="font-bold mt-10 mb-3 text-[20px]">7. User Responsibilities</div>
        <div>You agree to:</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            <span className="font-normal">
              Use the Site lawfully and ethically.
            </span>
          </li>
          <li className="mt-3 list-disc">
            <span className="font-normal ">
              Avoid actions that disrupt Site functionality or compromise security
              (e.g., hacking, data scraping).
            </span>
          </li>
          <li className="mt-3 list-disc">
            <span className="font-normal ">
              Refrain from uploading harmful content (viruses, malware).
            </span>
          </li>
        </ul>
        <div className="font-bold mt-10 mb-3 text-[20px]">6. Severability</div>
        <div>
          If any provision of these Terms is deemed invalid or unenforceable, the
          remaining provisions shall remain in full effect.
        </div>
        <div className="font-bold mt-10 mb-3 text-[20px]">7. Contact Us</div>
        <ul className="md:ml-10">
          <li className="mt-3 list-disc">
            If you have any questions or concerns about this Privacy Policy or our data practices,
            please contact us at:<a
              href="mailto:April@lopezpropertycare.com"
              className="ml-2 cursor-pointer leading-[30.2px] text-[16px] max-md:text-[10px] underline text-blue-600"
            >
              April@lopezpropertycare.com
            </a> or submit a request through <span
              className="cursor-pointer underline text-blue-600"
              onClick={() => setIsOpen(true)}
            >
              our form.
            </span>
          </li>
        </ul>
        <div className="font-bold text-xl mt-10">
          Last Updated: <span className="font-normal ">02/17/2025</span>
        </div>
      </section>
      <ContactModal open={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default TermsAndCondition;
